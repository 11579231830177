.background {
  background-color: #0d6739;
}

.footerMain {
  padding-top: 2.375rem;
  padding-bottom: 2.375rem;
  position: relative;
}

.footerCopyright {
  /* margin-top: 3.125rem; */
  color: white;
  text-align: center;
}

.footerList {
  margin-left: auto;
  margin-right: auto;
}
