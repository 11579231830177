.header {
  font-size: 100%;
  line-height: 1.5;
  color: #444;
  text-rendering: optimizeLegibility;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 0%);
  display: flex;
  flex-direction: column;
  content: 'viewport-units-buggyfill; min-height: 100vh;';
  color: #fff;
}

@media only screen and (max-width: 1000px) {
  .headerMain {
    font-size: 80%;
    padding: 1.5em 0;
  }
  .fullSizeNav {
    display: none;
  }
  .logoContent {
    font-size: 0.75em;
  }
  .header {
    font-size: 80%;
  }
}

@media only screen and (min-width: 1001px) {
  .smallSizeNav {
    display: none;
  }
  .headerMain {
    padding: 2.8125em 0;
  }
}

@media only screen and (max-width: 1024px) {
  .headerMain {
    font-size: 90%;
  }
  .header {
    font-size: 90%;
  }
}

.headerMain {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.headerMainScrolled {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  font-size: 100%;
  padding: 0.9em 0;
}

.headerTop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.background {
  background-color: #4a524b;
}

.centeredLarge {
  margin-left: auto;
  margin-right: auto;
  max-width: 88%;
}

.headerTopInner {
  display: table;
  width: 100%;
  min-height: 2em;
  font-size: 1rem;
  vertical-align: middle;
}

.logo {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.logoContent {
  min-width: 200px;
  text-align: left;
}

.logoTextLarge {
  font-size: 1.75em;
  position: relative;
  margin: 0;
  left: -2px;
  line-height: 1.2;
  white-space: nowrap;
}

.headerMenu {
  display: table-cell;
  padding-left: 1.5em;
}

.headerMenuContent {
  position: relative;
  top: 0.125em;
  margin: 0;
  float: right;
  font-size: 0.9em;
  text-align: right;
  text-transform: uppercase;
}

.headerMenuContent > li {
  display: inline-block;
  margin: 0.3125em 0 0 1.25em;
  padding-bottom: 0.5em;
  vertical-align: top;
  text-align: left;
}

.headerMenuContent > li > a {
  position: relative;
  display: inline-block;
  color: #fff;
}

.sfMenu {
  line-height: 1;
  padding: 0;
  list-style: none;
}

.sfMenu > li {
  position: relative;
}

.banner {
  position: relative;
  width: 100%;
  z-index: 6;
}

.bannerInner {
  display: table;
  position: relative;
  height: 100%;
  padding: 8% 0;
  max-width: 980px;
}

.bannerTitle {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0 2.5em;
}

.bannerH1 {
  display: block;
  margin-top: 1.3em;
  margin-bottom: 0;
  font-size: 2.7em;
  font-weight: 200;
  line-height: 1.2;
}

.hoverMenu {
  cursor: pointer;
}

.hoverMenu > ul {
  display: none;
  float: none;
  width: 200px;
  list-style: none;
}

.hoverMenu:hover > ul {
  display: block;
  top: 1.5625em;
  z-index: 12000;
}

.subMenu {
  position: absolute;
  padding: 0.75em 0.5em 0.75em 0.625em;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 12px rgb(0 0 0 / 15%);
}

.subMenu > li {
  padding: 5px;
  padding-left: 15px;
}
